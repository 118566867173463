import MainBackendAxios from '../utils/MainBackendAxios';
import {ApiResponse} from "../models/ApiResponse";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {PaginationData} from "../models/PaginationData";
import {OperationReport} from "../models/OperationReport";
import {GenericCreateResponseDto} from "../models/dtos/GenericCreateResponseDto";
import {GenericUpdateOrDeleteResponseDto} from "../models/dtos/GenericUpdateOrDeleteResponseDto";

export const OperationReportService = {
    getAllByCurrentUserCompanyAndFiltersAndPaginate: async (bodyParams: GetAllByCurrentUserCompanyAndFiltersAndPaginateRequestDto): Promise<ApiResponse<PaginationData<OperationReport>>> => {
        try {
            const response = await MainBackendAxios.post(`/operation-report/get-all-by-user-company-and-filters-and-paginate`, bodyParams);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    getAllByFiltersAndPaginate: async (bodyParams: GetAllByFiltersAndPaginateRequestDto): Promise<ApiResponse<PaginationData<OperationReport>>> => {
        try {
            const response = await MainBackendAxios.post(`/operation-report/get-all-by-filters-and-paginate`, bodyParams);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    findById: async (id: number): Promise<ApiResponse<OperationReport>> => {
        try {
            const response = await MainBackendAxios.get(`/operation-report/find-by-id/${id}`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    create: async (bodyParams: CreateOperationReportRequestDto): Promise<ApiResponse<GenericCreateResponseDto>> => {
        try {
            const response = await MainBackendAxios.post(`/operation-report/create`, bodyParams);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    update: async (id: number, bodyParams: UpdateOperationReportRequestDto): Promise<ApiResponse<GenericUpdateOrDeleteResponseDto>> => {
        try {
            const response = await MainBackendAxios.put(`/operation-report/update/${id}`, bodyParams);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
    delete: async (id: number): Promise<ApiResponse<GenericUpdateOrDeleteResponseDto>> => {
        try {
            const response = await MainBackendAxios.delete(`/operation-report/delete/${id}`);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
};

export interface GetAllByCurrentUserCompanyAndFiltersAndPaginateRequestDto {
    page: number;
    pageSize: number;
    sortField?: string;
    sortOrder?: string;
    term?: string;
}

export interface GetAllByFiltersAndPaginateRequestDto {
    page: number;
    pageSize: number;
    sortField?: string;
    sortOrder?: string;
    term?: string;
}

export interface CreateOperationReportRequestDto {
    name: string;
    operationTypeCode: string;
    url: string | null;
    companyId: number;
    brandId: number;
    tags: string[];
    additionalSettings: object;
}

export interface UpdateOperationReportRequestDto {
    name: string;
    operationTypeCode: string;
    url: string | null;
    brandId: number;
    tags: string[];
    additionalSettings: object;
}
