import {ItemInterface} from "../models/ItemInterface";

export const REPORT_TYPES_ITEMS: ItemInterface[] = [
    {
        value: 'IFRAME',
        label: 'URL'
    },
    {
        value: 'POWER_BI',
        label: 'Power BI'
    }
];
