import {ApiResponse} from "../models/ApiResponse";
import MainBackendAxios from "../utils/MainBackendAxios";
import {FunctionsHelper} from "../utils/FunctionsHelper";
import {PowerBiReportData} from "../models/PowerBiReportData";

export const PowerBiReportService = {
    getFullDataForReport: async (body: { reportId: string, groupId: string }): Promise<ApiResponse<PowerBiReportData>> => {
        try {
            const response = await MainBackendAxios.post(`/power-bi-report/get-full-data-for-report`, body);

            return {
                success: true,
                status: response.status,
                data: response?.data?.data,
            };
        } catch (err: any) {
            return FunctionsHelper.getErrorFormat(err);
        }
    },
};
