import React, {useEffect, useState} from 'react';
import {Button, Col, Divider, Form, GetProp, Input, message, Modal, Row, Upload, UploadProps} from "antd";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";

interface NewBrandModalProps {
    isOpen: boolean;
    handleModalCancel: () => void;
    parentId: number;
    submit: (companyId: number, name: string, logo: File | null) => void;
}

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

function NewBrandModal({isOpen, handleModalCancel, parentId, submit}: NewBrandModalProps) {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);

    const [imageUrl, setImageUrl] = useState<string>();
    const [imageFile, setImageFile] = useState<FileType | null>(null);

    useEffect(() => {
        if(isOpen) {
            form.resetFields();
            setImageUrl(undefined);
            setImageFile(null);
        }
    }, [isOpen]);

    const onFinish = async (values: any) => {
        setLoading(true);
        await submit(
            parentId,
            values.name,
            imageFile
        );
        setLoading(false);
    }

    const getBase64 = (img: FileType, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file: FileType) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Solo puedes cargar archivos tipo JPG o PNG');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('La imagen no puede superar los 2MB.');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange: UploadProps['onChange'] = (info) => {
        const file = info.file.originFileObj as FileType;

        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }

        if (info.file.status === 'done' || info.file.status === 'removed') {
            setLoading(false);
        }

        if (file && beforeUpload(file)) {
            setImageFile(file);
            getBase64(file, (url) => {
                setImageUrl(url);
                setLoading(false);
            });
        }
    };

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Cargar logo</div>
        </button>
    );

    return (
        <>
            {contextHolder}

            <Modal
                title="Crear marca"
                open={isOpen}
                onCancel={handleModalCancel}
                maskClosable={false}
                destroyOnClose
                footer={null}
                width={480}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={24} style={{ marginBottom: '15px' }}>
                        <Col xs={24} lg={24} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                            >
                                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="name"
                                label="Nombre"
                                rules={[{ required: true, message: 'Debes de ingresar el nombre' }]}
                            >
                                <Input type="text" disabled={loading} placeholder="Ingrese el nombre"/>
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row gutter={24}>
                        <Col xs={24}>
                            <Button onClick={() => { form.submit(); }} loading={loading} type="primary" block>Guardar cambios</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default NewBrandModal;
