import React, {useEffect, useState} from 'react';
import {Avatar, Dropdown, MenuProps, Modal, Space} from "antd";
import {User} from "../../../models/User";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {AuthService} from "../../../services/AuthService";
import AuthAction from "../../../redux/actions/authActions/Actions";
import {useThemeLayoutConfig} from "../../../config/ThemeDashboardLayoutConfig";
import { LogOut, Settings, User as UserIcon, HelpCircle } from 'lucide-react';
import {ExclamationCircleOutlined} from "@ant-design/icons";

import './ProfileMenu.scss';

interface ProfileMenuProps {
    setLoading: (value: boolean) => void;
}

function ProfileMenu({ setLoading }: ProfileMenuProps) {
    const [modal, contextModalHolder] = Modal.useModal();
    const themeConfig = useThemeLayoutConfig();
    const authUser: User = useSelector((state: any) => state.auth);
    const dispatch = useDispatch();
    const location = useLocation();
    const [items, setItem] = useState<MenuProps['items']>([]);

    useEffect(() => {
        setItem([
            {
                key: 'header',
                label: (
                    <div className="user-header">
                        <div className="user-info-container">
                            <Avatar
                                style={{
                                    backgroundColor: themeConfig.components?.Menu?.itemSelectedBg,
                                    color: themeConfig.token?.colorPrimary
                                } }
                                size="large"
                            >
                                <span
                                    style={{
                                        textTransform: 'uppercase'
                                    }}
                                >
                                    { authUser.name.slice(0, 1) + authUser.lastname.slice(0, 1) }
                                </span>
                            </Avatar>

                            <div>
                                <div className="user-name">{authUser.name}</div>
                                <div className="user-company">{authUser.company.name}</div>
                                <div className="user-email">{authUser.email}</div>
                            </div>
                        </div>
                    </div>
                ),
                type: 'group',
            },
            {
                type: 'divider',
            },
            {
                key: 'profile',
                label: 'Mi Perfil',
                icon: <UserIcon className="menu-icon" />,
                disabled: true
            },
            {
                key: 'settings',
                label: 'Configuración',
                icon: <Settings className="menu-icon" />,
                disabled: true
            },
            {
                type: 'divider',
            },
            {
                key: 'logout',
                label: 'Cerrar Sesión',
                icon: <LogOut className="menu-icon" />,
                danger: true,
                onClick: handleLogout
            },
        ]);
    }, []);

    const handleLogout = () => {
        modal.confirm({
            title: 'Confirmación',
            icon: <ExclamationCircleOutlined />,
            content: <span>¿Estás seguro que deseas cerrar sesión?</span>,
            okText: 'SI',
            cancelText: 'NO',
            onOk: logout
        });
    }

    const logout = async () => {
        setLoading(true);

        if (authUser.token) {
            await AuthService.logout();
        }

        dispatch(AuthAction.signOut({}));
        setLoading(false);
    }

    return (
        <Space direction="vertical">
            {contextModalHolder}
            <Space wrap>
                <Dropdown
                    menu={{ items }}
                    trigger={["click"]}
                    placement="bottomLeft"
                    arrow
                >
                    <div onClick={e => e.preventDefault()}>
                        <Space size={16} wrap>
                            <Avatar
                                style={{
                                    backgroundColor: themeConfig.components?.Menu?.itemSelectedBg,
                                    color: themeConfig.token?.colorPrimary,
                                    cursor: 'pointer'
                                } }
                            >
                                <span
                                    style={{
                                        textTransform: 'uppercase'
                                    }}
                                >
                                    { authUser.name.slice(0, 1) + authUser.lastname.slice(0, 1) }
                                </span>
                            </Avatar>
                        </Space>
                    </div>
                </Dropdown>
            </Space>
        </Space>
    );
}

export default ProfileMenu;
